import Layout from "./components/Layout";

import DeliveryDelay from "./modals/DeliveryDelay"; // 배송 지연 -> TODO 추후 모달로 처리
import DeliveryLost from "./modals/DeliveryLost"; // 분실 완료 -> TODO 추후 모달로 처리

import Cs from "./pages/Cs";
import DeliveriesNew from "./pages/DeliveriesNew";
import History from "./pages/History";
import Password from "./pages/Password";
import SettingContract from "./pages/SettingContract";
import SettingPushNotification from "./pages/SettingPushNotification";

export const RouterInfo = [
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <div>aa</div>,
        label: "main",
      },
      {
        path: "/cs",
        element: <Cs />,
        label: "cs",
      },
      {
        path: "/deliveries-new",
        element: <DeliveriesNew />,
        label: "deliveries-new",
      },
      {
        path: "/delivery-delay",
        element: <DeliveryDelay />,
        label: "delivery-delay",
      },
      {
        path: "/delivery-lost",
        element: <DeliveryLost />,
        label: "delivery-lost",
      },
      {
        path: "/history",
        element: <History />,
        label: "history",
      },
      {
        path: "/password",
        element: <Password />,
        label: "update-password",
      },
      {
        path: "/setting-contract",
        element: <SettingContract />,
        label: "setting-contract",
      },
      {
        path: "/setting-push-notification",
        element: <SettingPushNotification />,
        label: "setting-push-notification",
      },
    ],
  },
];
