/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { useDeliveriesStore, useLoadingStore } from "../stores/hooks";

import { LOST_REASONS, REASON_ETC } from "../utils/lost";

const ReasonItem = ({ active, reason, onSelect }) => {
  const handleSelect = () => {
    onSelect(reason);
  };

  return (
    <div>
      <label className="block mb-10" onClick={handleSelect}>
        <input
          type="radio"
          name="corp"
          defaultChecked={!!active}
          className="mr-5"
        />
        {reason.text}
      </label>
    </div>
  );
};

function DeliveryLost() {
  const params = new URLSearchParams(window.location.search);
  const bookId = params.get("bookId");

  const { delivery } = useDeliveriesStore().state;
  const { ...actions } = useDeliveriesStore();

  const { ...loadingActions } = useLoadingStore();

  const { register, handleSubmit, reset, setValue, watch } = useForm();

  const [load, setLoad] = useState(false);

  useEffect(() => {
    if (bookId) {
      fetchById();
    }
  }, [bookId]);

  useEffect(() => {
    reset({
      code: LOST_REASONS[0].code,
    });

    setLoad(true);
  }, []);

  const fetchById = async () => {
    try {
      await actions.fetchById(bookId);
    } catch (e) {
      window.alert(`배송 조회에 실패했습니다. ${e.message}`);
    }
  };

  // 분실완료 취소
  const handleCancel = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ close: true }));
    }
  };

  // 사유 선택
  const handleSelectReason = (reason) => {
    setValue("code", reason.code);
    setValue("text", reason.text);
    setValue("etcText", "");
  };

  // 직접입력 전체 삭제
  const handleDeleteEtcText = () => {
    setValue("etcText", "");
  };

  const onSubmit = async ({ code, text, etcText }) => {
    try {
      loadingActions.startLoading();

      const reasonText = `${text}${
        code === REASON_ETC && etcText ? `(${etcText})` : ""
      }`;

      const data = {
        bookId,
        reason: reasonText,
        reasonType: code,
      };

      await actions.setLost(data);

      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({ submitted: true, reasonText }),
        );
      }
    } catch (e) {
      window.alert(`분실완료처리에 실패했습니다.. ${e.message}`);
    }

    loadingActions.finishLoading();
  };

  return (
    <div className="p-15">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-20">
          <h5 className="font-medium text-18 mb-10">
            {delivery?.bookId} {delivery?.receiverName}
            <br />
            분실완료 사유를 선택해주세요.
          </h5>
          <div>
            {load &&
              LOST_REASONS.map((reason) => {
                return (
                  <ReasonItem
                    key={reason.code}
                    active={reason.code === watch("code")}
                    reason={reason}
                    onSelect={handleSelectReason}
                  />
                );
              })}
            <input name="code" {...register("code")} hidden />
            <input name="text" {...register("text")} hidden />
            <div className="bg-[#f6f6f6] p-5 flex justify-between items-center ml-10">
              <input
                type="text"
                className="bg-transparent max-w-[calc(100vw-120px)] flex-1"
                {...register("etcText")}
                disabled={watch("code") !== REASON_ETC}
              />
              <button
                type="button"
                className="text-[#999] p-5 w-50"
                onClick={handleDeleteEtcText}
              >
                삭제
              </button>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center">
          <button type="button" className="flex-1 p-10" onClick={handleCancel}>
            취소
          </button>
          <button type="submit" className="text-orange flex-1 p-10">
            확인
          </button>
        </div>
      </form>
    </div>
  );
}

export default DeliveryLost;
